<template>
  <v-container>
    <div class="d-flex mb-4">
      <h2>Manage Artists</h2>
      <v-spacer></v-spacer>
      <v-btn color="success" class="mr-4" @click.native="createArtist()">
        New Artist
      </v-btn>
    </div>
    <v-data-table @click:row="editArtist" :items="artists" :headers="headers" :disable-pagination="true" :hide-default-footer=true dense>
      <template v-slot:[`item.bio`]="{ item }">
        <div v-html="item.bio"></div>
      </template>
    </v-data-table>
    <artist-dialog v-if="openDialog" v-model='openDialog' :data="currentArtist" :artistCodes="artistCodes" @close="closeDialog" />
  </v-container>
</template>

<script>
import ArtistDialog from './components/ArtistDialog'
import ArtistApi from '@/api/admin/artist'

export default {
  components: {
    ArtistDialog
  },
  data() {
    return {
      openDialog: false,
      artists: [],
      artistCodes: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Code', value: 'artistCode', width: 100 },
        { text: 'Signature Image', value: 'signatureImage' },
        { text: 'Status', value: 'status' },
      ],
      selectedFile: null,
      validData: false,
      valid: true,
      processing: false,
      progress: 0,
      uploadSuccess: false,
      currentArtist: null,
      mode: 'preview'
    }
  },
  async created() {
    await this.loadArtists()
  },
  mounted: function () {
  },
  computed: {
  },
  watch: {},
  methods: {
    loadArtists() {
      ArtistApi.loadAllArtists().then((results) => {
        this.artists = results.data
        this.artistCodes = this.artists.map(item => item.artistCode)
        this.loadingData = false
      }).catch(
        err => {
          console.log('err', err)
          this.loadingData = false
        }
      )
    },
    createArtist() {
      this.currentArtist = null
      this.openDialog = true
    },
    editArtist(evt) {
      this.currentArtist = evt
      this.openDialog = true
    },
    closeDialog(element) {
      this.loadArtists()
      this.openDialog = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep tbody tr :hover {
  cursor: pointer;
}
</style>
